@import "_reset";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Noto+Sans+Thai:wght@300;400;700;900&display=swap");

:root {
  font-size: 16px;
}

body {
  font-family: "Lato", "Noto Sans Thai", sans-serif;
  background: linear-gradient(
    91.68deg,
    #ffffff 7.36%,
    #c7ebf7 83.07%,
    #feffe4 101.3%
  );
  line-height: 1.5;
  color: #0d0c22;

  > iframe {
    display: none;
  }
}

img {
  max-width: 100%;
}

@keyframes animateBtn {
  0% {
    box-shadow: 0 0 0 0 #c4deff;
  }
  90% {
    box-shadow: 0 0 0 6px #c4deff;
  }
  100% {
    box-shadow: 0 0 0 6px transparent;
  }
}

@keyframes animateBtnShadow {
  0% {
    box-shadow: 0 0 0 0 #e2edfb8c;
  }
  90% {
    box-shadow: 0 0 0 10px #e2edfb8c;
  }
  100% {
    box-shadow: 0 0 0 10px transparent;
  }
}

.effect-btn:not(.Mui-disabled) {
  position: relative;
  transition: 0.2s;
  animation: animateBtn 1s linear infinite;

  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    transform: translate(-50%, -50%);
    transition: 0.2s;
    border-radius: 8px;
    animation: animateBtnShadow 1s linear infinite;
  }
}
